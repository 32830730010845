import * as React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Nie znaleiozno</h1>
      <p>Nie udało się znaleźć podanej strony. Smutek. Daj nam znać!</p>
    </div>
  </Layout>
);

export default NotFoundPage;
